import React from 'react';
import BookDemoButtonComponent from '../../BookDemoButton';
import { useTenantContext } from '@/Framework/Tenant/TenantContext';
import useScheduleDemoModal from '../useScheduleDemoModal';

interface IProps {
  buttonComponent?: React.ElementType,
  title?: string,
  className?: string,
}

const BookDemoButton = ({ buttonComponent, title, className }: IProps) => {
  const { tenant } = useTenantContext();

  const {
    isScheduleDemoModalVisible,
    showScheduleDemoModal,
    hideScheduleDemoModal,
    ScheduleDemoModal,
  } = useScheduleDemoModal();

  return (
    <BookDemoButtonComponent
      isScheduleDemoModalVisible={ isScheduleDemoModalVisible }
      showScheduleDemoModal={ showScheduleDemoModal }
      hideScheduleDemoModal={ hideScheduleDemoModal }
      scheduleDemoModal={ ScheduleDemoModal }
      tenant={ tenant }
      buttonComponent={ buttonComponent }
      title={ title }
      className={ className }
    />
  );
};

export default BookDemoButton;
