import React, { useEffect } from 'react';
import cn from 'classnames';
import ScheduleDemoContext, { useScheduleDemoContext } from '@/Framework/UI/Organisms/ScheduleDemo/ScheduleDemoContext';
import ScheduleDemoForm from '@/Framework/UI/Organisms/ScheduleDemo/ScheduleDemoForm';
import ScheduleDemoFormSuccess from './ScheduleDemoFormSuccess';
import { Spinner, Modal } from '@dealroadshow/uikit';

import successStamp from './assets/successStamp.inline.svg';
import scheduleDemoStamp from './assets/scheduleDemoStamp.inline.svg';
import styles from './scheduleDemoModal.scss';

interface IProps {
  tenant: string,
  // passed from Finsight's OurSolutions
  tenantName: string,
  closeModal: () => void,
  afterClose: () => void,
}

const ScheduleDemoModal = ({
  tenant,
  tenantName,
  closeModal,
  afterClose,
}: IProps) => {
  const { isSubmitting, isSubmitted, reset } = useScheduleDemoContext();

  useEffect(reset, [tenant]);

  return (
    <Modal
      hideOnOverlayClicked
      className={ cn(styles.scheduleDemoModal, `tenant-${ tenant }`) }
      isVisible
      onCloseClicked={ closeModal }
      afterClose={ afterClose }
      dataTest={ isSubmitted ? `${ tenant }ScheduleDemoModalSuccess` : `${ tenant }ScheduleDemoModal` }
      title={ (
        <div className={ styles.modalHeader }>
          <div className={ styles.modalHeaderContainer }>
            <div className={ styles.modalHeaderTitle }>{ isSubmitted ? 'Demo Scheduled' : 'Schedule a Demo' }</div>
            { !!tenantName && (<p className={ styles.tenantName }>for { tenantName }</p>) }
          </div>
          <div className={ styles.modalHeaderStamp }>
            { isSubmitted ? (
              <span dangerouslySetInnerHTML={ { __html: successStamp } } />
            ) : (
              <span dangerouslySetInnerHTML={ { __html: scheduleDemoStamp } } />
            ) }
          </div>
        </div>
      ) }
    >
      <Spinner
        isVisible={ isSubmitting }
        isFixed
        overlay
      />
      { isSubmitted
        ? <ScheduleDemoFormSuccess />
        : (
          <>
            <div className={ styles.modalContentContainer }>
              <p className={ styles.contentDescription }>
                It takes 15-30 minutes and we operate on your availability.<br />
                Our team will send a meeting planner within twenty minutes as confirmation.
              </p>
            </div>
            <ScheduleDemoForm tenant={ tenant } />
          </>
        ) }
    </Modal>
  );
};

export default (props) => (
  <ScheduleDemoContext>
    <ScheduleDemoModal { ...props } />
  </ScheduleDemoContext>
);
