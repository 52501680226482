import React from 'react';
import dataroomUrl from '@/dataroom/infrastructure/dataroomUrl';
import drsUrl from '@/dealroadshow/infrastructure/url/drsUrl';
import URL from '@/evercall/Router/urlConstants';
import config from '@/Framework/config';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import evercallUrl from '@/evercall/infrastructure/evercallUrl';

import BookDemoButton from '@/Framework/UI/Organisms/ScheduleDemo/ScheduleDemoModalLegacy/BookDemoButton';

import styles from './layout.scss';
import footerLinkStyles from '@/Framework/UI/Organisms/StickyFooter/FooterLink/footerLink.scss';

export const EVERCALL_LANDING_HEADER_MENU: Array<{ key: string, title: string, path: string }> = [
  {
    key: 'features',
    title: 'Features',
    path: URL.FEATURES,
  },
  {
    key: 'pricing',
    title: 'Pricing',
    path: URL.PRICING,
  },
  {
    key: 'process',
    title: 'Process',
    path: URL.PROCESS,
  },
];

export const EVERCALL_LANDING_FOOTER_CONFIG = (
  {
    navigation: [
      {
        path: finsightWebUrl.getComplianceUrl(),
        title: 'Compliance',
        shouldOpenInNewTab: true,
      },
      {
        path: finsightWebUrl.getPrivacyPolicyUrl(),
        title: 'Privacy',
        shouldOpenInNewTab: true,
      },
      {
        path: finsightWebUrl.getSecurityPolicyUrl(),
        title: 'Security',
        shouldOpenInNewTab: true,
      },
      {
        path: finsightWebUrl.getTermsOfUseUrl(),
        title: 'Terms',
        shouldOpenInNewTab: true,
      },
    ],
    content: [
      {
        key: 'company',
        title: 'Company',
        columnClassName: styles.footerCol,
        collection: [
          {
            title: 'About',
            path: finsightWebUrl.getAboutUrl(),
            shouldOpenInNewTab: true,
          },
          {
            title: 'Careers',
            path: finsightWebUrl.getCareersUrl(),
            shouldOpenInNewTab: true,
          },
          {
            title: 'Contact',
            path: finsightWebUrl.getContactUsUrl(),
            shouldOpenInNewTab: true,
          },
          {
            title: 'Solutions',
            path: finsightWebUrl.getOurProductsUrl(),
            shouldOpenInNewTab: true,
          },
        ],
      },
      {
        key: 'evercall',
        title: 'Evercall',
        columnClassName: styles.footerCol,
        collection: [
          { title: 'Features', path: URL.FEATURES },
          { title: 'Pricing', path: URL.PRICING },
          { title: 'Process', path: URL.PROCESS },
          {
            itemCallback: () => (
              // eslint-disable-next-line react/jsx-filename-extension
              <BookDemoButton
                className={ footerLinkStyles.link }
                buttonComponent="a"
                title="Schedule"
              />
            ),
          },
        ],
      },
      {
        key: 'products',
        title: 'Products',
        columnClassName: styles.footerCol,
        collection: [
          {
            title: 'Deal Roadshow',
            path: drsUrl.getUrl(),
            shouldOpenInNewTab: true,
          },
          {
            title: 'DealVDR',
            path: dataroomUrl(config.tenant.tenantDealvdr.code).getUrl(),
            shouldOpenInNewTab: true,
          },
          {
            title: 'Evercall',
            path: evercallUrl.getUrl(),
            shouldOpenInNewTab: true,
          },
          {
            title: 'Finsight.com',
            path: finsightWebUrl.getUrl(),
            shouldOpenInNewTab: true,
          },
          {
            title: 'InvestorSet',
            path: dataroomUrl(config.tenant.tenantInvestorSet.code).getUrl(),
            shouldOpenInNewTab: true,
          },
          {
            title: 'VeriSend',
            path: dataroomUrl(config.tenant.tenantVeriSend.code).getUrl(),
            shouldOpenInNewTab: true,
          },
          {
            title: '17g5.com',
            path: dataroomUrl(config.tenant.tenant17g5.code).getUrl(),
            shouldOpenInNewTab: true,
          },
        ],
      },
      {
        key: 'sales',
        title: 'Sales & Support',
        collection: [
          { title: 'Americas', phoneNumber: '+1 888 997 6610 ' },
          { title: 'APAC', phoneNumber: '+852 3018 1600' },
          { title: 'EMEA', phoneNumber: '+44 80 8178 7364' },
          {
            title: config.tenant.tenantEvercall.supportEmail,
            path: `mailto:${ config.tenant.tenantEvercall.supportEmail }?subject=Evercall Sales %26 Support Request`,
            linkClassName: styles.mailLink,
          },
        ],
      },
    ],
  }
);
