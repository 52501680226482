import { getDataroomTenantConfigs } from '@/dataroom/domain/config';
import baseUrl from '@/Framework/url/baseUrl';
import {
  buildUrl,
  appendSearchParams,
} from '@/Framework/url/helpers';
import usersUrl from '@/users/infrastructure/usersUrl';
import dmPortalUrl from '@/dmPortal/infrastructure/url/dmPortalUrl';
import CallbackUrl from '@/Framework/url/CallbackUrl';
import DataroomTenantConfig from '@/dataroom/application/config/DataroomTenantConfig';
import drsUrl from '@/dealroadshow/infrastructure/url/drsUrl';
import config from '@/Framework/config';
import { DataroomTenant } from '@/dataroom/domain/vo/types/DataroomTenant';
import finsightWebUrl from '@/finsight/infrastructure/finsightWebUrl';
import evercallUrl from '@/evercall/infrastructure/evercallUrl';
import { savingsGuaranteeFootnoteUrl } from '@/Framework/UI/Pages/Landing/Sections/constants';
import { getUnixTimestamp } from '@/Framework/DateTime/getUnixTimestamp';
import shouldUseInternalNetwork from '@/Framework/Router/Next/shouldUseInternalNetwork';

export class DataroomURL {
  public constructor(private tenant: DataroomTenant | ':tenant') {
  }

  public getBaseUrl(...uris: string[]): string {
    return buildUrl(config.protocol, DataroomTenantConfig.fromCode(this.tenant).hostname, ...uris);
  }

  public getUrl(...uris: string[]): string {
    return this.getBaseUrl(...uris);
  }

  public getProxyApiRpcPath(): string {
    return `/proxy/api/${ this.tenant }/rpc`;
  }

  public getApiUrl(...uris: string[]): string {
    return baseUrl.getApiUrl(this.tenant, ...uris);
  }

  public getRpcUrl(): string {
    if (shouldUseInternalNetwork()) {
      return this.getInternalRpcUrl();
    }

    if (
      window.location.hostname === DataroomTenantConfig.fromCode(this.tenant).hostname &&
      window.location.hostname !== config.tenant.users.hostname
    ) {
      return this.getUrl(this.getProxyApiRpcPath());
    }

    return this.getApiUrl('/rpc');
  }

  getInternalRpcUrl(): string {
    return `${ config.tenant[this.tenant].api.internal }/rpc`;
  }

  public getDirectRpcUrl(): string {
    // DO NOT USE THIS DataroomURL.getApiUrl() as it returns the wrong URL on datarooms domains
    return baseUrl.getRestUrl(this.tenant, '/rpc');
  }

  public getFileUrl(...uris: string[]): string {
    return this.getApiUrl(...uris);
  }

  public getAssetsLocation(...uris: string[]): string {
    return this.getUrl(baseUrl.getAssetsLocation(...uris));
  }

  public getUploadLogoUrl(): string {
    return this.getApiUrl(`/api/v1/${ DataroomTenantConfig.fromCode(this.tenant).options.tenantPathName }/logo`);
  }

  public getUploadFileUrl(): string {
    return this.getApiUrl('/api/v1/files');
  }

  public getUsersDownloadTemplateUrl(dataroomName: string): string {
    return this.getApiUrl(`/api/v1/${ DataroomTenantConfig.fromCode(this.tenant).options.tenantPathName }/${ dataroomName }/users/import/download-template`);
  }

  public getUsersDownloadErrorsUrl(dataroomName: string, hash: string): string {
    return this.getApiUrl(`/api/v1/${ DataroomTenantConfig.fromCode(this.tenant).options.tenantPathName }/${ dataroomName }/users/import/download-errors/${ hash }`);
  }

  public getUsersUploadUrl(dataroomName: string) {
    return this.getApiUrl(`/api/v1/${ DataroomTenantConfig.fromCode(this.tenant).options.tenantPathName }/${ dataroomName }/users/import/parse`);
  }

  public getBulkUploadTemplateUrl(dataroomId: number): string {
    return this.getApiUrl(`/api/v1/qna/bulk-upload/template/download?dataroom_id=${ dataroomId }`);
  }

  public getQnABulkUploadUrl(): string {
    return this.getApiUrl('/api/v1/qna/bulk-upload/upload');
  }

  public getDownloadFileUrl(dataroomName: string, fileId: number): string {
    return this.getApiUrl(
      `/api/v1/${ DataroomTenantConfig.fromCode(this.tenant).options.tenantPathName }/${ dataroomName }/files/${ fileId }/download`,
    );
  }

  public getDownloadExportByHashUrl(dataroomName: string, hash: string): string {
    return this.getDownloadByHashUrl(dataroomName, 'basic-export-report', hash);
  }

  public getDownloadArchiveByHashUrl(dataroomName: string, hash: string): string {
    return this.getDownloadByHashUrl(dataroomName, 'archives/build-archive', hash);
  }

  public getDownloadByHashUrl(dataroomName: string, type: string, hash: string): string {
    return this.getApiUrl(
      `/api/v1/${ DataroomTenantConfig.fromCode(this.tenant).options.tenantPathName }/${ dataroomName }/${ type }/${ hash }/download`,
    );
  }

  public getExploreSetsABSUrl(): string {
    return this.getUrl('/explore-sets/abs');
  }

  public getExploreSetsCorporateUrl(): string {
    return this.getUrl('/explore-sets/corporate');
  }

  public getHowItWorksUrl(): string {
    return this.getUrl('/how-it-works');
  }

  public getSavingsGuaranteeFootnoteUrl(): string {
    return this.getUrl(savingsGuaranteeFootnoteUrl);
  }

  public getHelpUrl(): string {
    return buildUrl(window.location.protocol, 'vdr-help.finsight.com');
  }

  public getFollowPath(dataroomId: number | ':dataroomId'): string {
    return `/${ this.tenant }/auto_send_follow/${ dataroomId }`;
  }

  public getAccessRequestPath(dataroomId: number | ':dataroomId', dataroomName: string): string {
    return `/${ this.tenant }/auto_send_access_request/${ dataroomId }/${ dataroomName }`;
  }

  public getAccessRestrictedPath(dataroomName: string): string {
    return `/${ this.tenant }/access-restricted/${ dataroomName }`;
  }

  public getFollowUrl(dataroomId: number): string {
    return dmPortalUrl.getUrl(this.getFollowPath(dataroomId));
  }

  public getAccessRequestUrl(dataroomId: number, dataroomDisplayName: string): string {
    // encodeURI is used here because of decoding bug in history https://github.com/remix-run/history/pull/656
    // According to the roadmap https://github.com/remix-run/history/issues/689 it will be removed in the 5th version
    // of history. It is compatible with a current list of investorset's names without special characters on
    // production. And it will work as expected with existing links. TODO: @stanislav-bilov @vladimirlomonos - Take a
    // look on this when you update history package. Don't forget to remove encodeURI on history and react-router
    // packages update.
    return dmPortalUrl.getUrl(
      this.getAccessRequestPath(dataroomId, encodeURI(encodeURIComponent(dataroomDisplayName))),
    );
  }

  public getAccessRestrictedUrl(dataroomDisplayName: string): string {
    // encodeURI is used here because of decoding bug in history https://github.com/remix-run/history/pull/656
    // According to the roadmap https://github.com/remix-run/history/issues/689 it will be removed in the 5th version
    // of history. It is compatible with a current list of investorset's names without special characters on
    // production. And it will work as expected with existing links. TODO: @stanislav-bilov @vladimirlomonos - Take a
    // look on this when you update history package. Don't forget to remove encodeURI on history and react-router
    // packages update.
    return dmPortalUrl.getUrl(
      this.getAccessRestrictedPath(encodeURI(encodeURIComponent(dataroomDisplayName))),
    );
  }

  public getPreviewerPasServiceUrl(dataroomName: string, fileId: number): string {
    return this.getBaseUrl(
      `/api/v1/${ DataroomTenantConfig.fromCode(this.tenant).options.tenantPathName }/${ dataroomName }/files/${ fileId }/pas-service`,
    );
  }

  public getPreviewUrl(dataroomName: string, fileId: number): string {
    return this.getBaseUrl(
      `api/v1/${ DataroomTenantConfig.fromCode(this.tenant).options.tenantPathName }/${ dataroomName }/files/preview?fileId=${ fileId }`,
    );
  }

  public getOriginalPreviewUrl(dataroomName: string, fileId: number): string {
    return this.getBaseUrl(
      `api/v1/${ DataroomTenantConfig.fromCode(this.tenant).options.tenantPathName }/${ dataroomName }/redaction/get-original?fileId=${ fileId }`,
    );
  }

  public getLoginUrl(callbackUrl: string, errorCode: string | number = null): string {
    const {
      protocol,
      hostname,
      pathname,
    } = new URL(usersUrl.getLoginUrl('', ''));

    return appendSearchParams(buildUrl(protocol, hostname, pathname), {
      callbackUrl,
      tenant: this.tenant,
      t: getUnixTimestamp(),
      ...(errorCode ? { error_code: errorCode } : {}),
    });
  }

  public getLoginUrlWithCallBackToDmPortal(): string {
    return this.getLoginUrl(dmPortalUrl.getUrl(`/${ this.tenant }`));
  }

  public getLogoutUrl(callbackUrl: string = this.getBaseUrl()): string {
    return appendSearchParams(usersUrl.getProxyApiLogoutPath(), {
      callbackUrl: this.getDataroomLogoutUrl(callbackUrl),
      t: getUnixTimestamp(),
    });
  }

  public getDataroomLogoutUrl(callbackUrl: string): string {
    return appendSearchParams(this.getBaseUrl('/api/v1/user/logout'), {
      callbackUrl,
      t: getUnixTimestamp(),
    });
  }

  public getRegisterUrl(callbackUrl: string = CallbackUrl.fromUrl()): string {
    const {
      protocol,
      hostname,
      pathname,
    } = new URL(usersUrl.getRegisterUrl('', ''));

    return appendSearchParams(buildUrl(protocol, hostname, pathname), {
      callbackUrl,
      tenant: this.tenant,
      t: getUnixTimestamp(),
    });
  }

  public getDataroomListTwoFactorRedirectUrl(entityName: string, callbackUrl: string = window.location.href): string {
    const {
      protocol,
      hostname,
      pathname,
    } = new URL(dmPortalUrl.getUrl(this.tenant));

    return appendSearchParams(buildUrl(protocol, hostname, pathname), {
      dataroomUrl: callbackUrl,
      showTwoFactor: 1,
      dataroomName: entityName,
    });
  }

  static getEvercallUrl() {
    return evercallUrl.getUrl();
  }

  static getContactUsUrl() {
    return finsightWebUrl.getContactUsUrl();
  }

  static getFinsightWebUrl(...uris) {
    return finsightWebUrl.getUrl(...uris);
  }

  static getComplianceUrl() {
    return finsightWebUrl.getComplianceUrl();
  }

  static getPrivacyPolicyUrl() {
    return finsightWebUrl.getPrivacyPolicyUrl();
  }

  static getSecurityPolicyUrl() {
    return finsightWebUrl.getSecurityPolicyUrl();
  }

  static getTermsOfUseUrl() {
    return finsightWebUrl.getTermsOfUseUrl();
  }

  static getAboutUrl() {
    return finsightWebUrl.getAboutUrl();
  }

  static getCareersUrl() {
    return finsightWebUrl.getCareersUrl();
  }

  static getOurProductsUrl() {
    return finsightWebUrl.getOurProductsUrl();
  }

  static getDrsUrl() {
    return drsUrl.getUrl();
  }

  static getTenantUrlPrefixes(): Set<string> {
    return getDataroomTenantConfigs()
      .reduce(
        (acc: Set<string>, dataroomConfig) => acc.add(dataroomConfig.options.tenantUrlPrefix),
        new Set<string>(),
      );
  }
}

export default (tenant: DataroomTenant | ':tenant') => new DataroomURL(tenant);
