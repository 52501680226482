// TODO: add favicon for Evercall
import React from 'react';
import Head from 'next/head';
import favicon128 from './assets/favicon-128.png';
import favicon16 from './assets/favicon-16.png';
import favicon32 from './assets/favicon-32.png';
import favicon64 from './assets/favicon-64.png';
import iosIcon120 from './assets/ios-icon-120.png';
import iosIcon152 from './assets/ios-icon-152.png';
import iosIcon167 from './assets/ios-icon-167.png';
import iosIcon180 from './assets/ios-icon-180.png';

function Favicon() {
  return (
    <Head>
      <link rel="icon" href={ favicon16 } sizes="16x16" type="image/x-icon" />
      <link rel="icon" href={ favicon32 } sizes="32x32" type="image/x-icon" />
      <link rel="icon" href={ favicon64 } sizes="64x64" type="image/x-icon" />
      <link rel="icon" href={ favicon128 } sizes="128x128" type="image/x-icon" />
      <link rel="apple-touch-icon" href={ iosIcon120 } sizes="120x120" />
      <link rel="apple-touch-icon" href={ iosIcon152 } sizes="152x152" />
      <link rel="apple-touch-icon" href={ iosIcon167 } sizes="167x167" />
      <link rel="apple-touch-icon" href={ iosIcon180 } sizes="180x180" />
    </Head>
  );
}

export default Favicon;
